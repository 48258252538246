import React from 'react';
import styled from 'styled-components';
import { FaHtml5, FaCss3, FaJs, FaPython, FaDatabase, FaGit, FaReact, FaCode,  FaGithub, FaUser, FaEnvelope} from 'react-icons/fa';

const Container = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  padding: 20px;
  display: flex; 
  flex-direction: row; 
  justify-content: space-between;
`;

const Section = styled.section`
  margin-bottom: 20px;
  margin-right: 100px;
  flex: 1; /* 幅を均等に分割 */
`;


const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const ListItem = styled.li`
  font-size: 1.5rem;
  margin-bottom: 5px;
  list-style-type: none; 
`;

const Paragraph = styled.p`
  margin-bottom: 5px;
  font-size: 1.5rem;
`;

const IconWrapper = styled.span`
  margin-right: 10px; /* アイコンとテキストの間に5pxのマージンを追加 */
`;

const Skill = ({ icon, text }) => (
  <ListItem>
    <IconWrapper>{icon}</IconWrapper>
    {text}
  </ListItem>
);

const Home = () => {
  return (
    <Container>
      <Section>
        <SubTitle>Personal</SubTitle>
        <div>
          <ul>
            <ListItem><strong><FaUser />Name:</strong> 猪股 航平 (Inomata Kohei)</ListItem>
            <ListItem><strong><FaEnvelope />Mail:</strong> inomata.kohei2001@gmail.com</ListItem>
            <ListItem><strong><FaGithub />Github:</strong><a href ="https://github.com/tontatonta">https://github.com/tontatonta</a></ListItem>
          </ul>
        </div>
        <SubTitle>Education:</SubTitle>
        <div>
          <ul>
            <ListItem>静岡大学 工学部機械工学科 卒業</ListItem>
            <ListItem>静岡大学大学院 総合科学技術研究科</ListItem>
            <ListItem>工学専攻機械工学コース 在学中</ListItem>
          </ul>
        </div>
      </Section>

      <Section>
        <SubTitle>Skills</SubTitle>
        <div>
          <Paragraph>
            <strong> Programming Languages:</strong>
            <ul>
              <Skill icon={<FaHtml5 />} text="HTML" />
              <Skill icon={<FaCss3 />} text="CSS" />
              <Skill icon={<FaJs />} text="JavaScript" />
              <Skill icon={<FaPython />} text="Python" />
              <Skill icon={<FaDatabase />} text="SQL" />
            </ul>
          </Paragraph>
          <Paragraph><strong>Framework:</strong>
            <ul>
              <Skill icon={<FaReact />} text="React" />
            </ul>
          </Paragraph>
          <Paragraph><strong>Tools:</strong>
          <ul>
              <Skill icon={<FaGit />} text="Git" />
              <Skill icon={<FaCode />} text="VSCode" />
            </ul>
          </Paragraph>
        </div>
      </Section>
    </Container>
  );
}

export default Home;