import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ProjectLink = styled.a`
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  &:hover {
    text-decoration: underline;
  }
`;

const ProjectTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const ProjectAbout = styled.div`
  margin-left: 20px;
  text-align: left;
`;

const AboutTitle = styled.strong`
  display: block;
  margin-bottom: 5px;
  text-align: center;
`;

const AboutDescription = styled.p`
  margin-bottom: 10px;
  white-space: pre-line;
`;

const ProjectCard = styled.div`
  border: 1px solid #ddd; 
  border-radius: 5px; 
  padding: 20px; 
  background-color: rgb(200,255,200);
  transition: box-shadow 0.3s ease; 
  
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
`;

const ProjectImage = styled.img`
max-width: 100%;
height: auto;
border-radius: 5px;
margin: 0 auto; 
display: block;
`;




const ProjectCardContent = styled.div`
  flex: 1;
`;


const projectsData = [
  {
    title: "テトリス (チーム開発)",
    link: "https://github.com/tontatonta/Tetiris-teamdev",
    about: "テトリスを3人でGithubを用いてチーム開発をしました。機能は以下の通りです。\n基本機能：\n・ブロックの操作（左右・下・回転）\n・次に落ちてくるブロックの表示\n・横一列にブロックが揃った際に消え、スコアが加算される機能\n・スコア加算 / 表示\n・過去のベストスコア表示\n・一時停止 / 再開機能ボタン\n・BGM\n・その他効果音（一列消えた時 / ゲームオーバー時 / ブロックを回転させた時 / 新しくブロックが生成された時）\n・ブロックの落下位置をリアルタイムで予測 / 表示\n・ハードドロップ機能\n操作方法：\n・右矢印キー：右へ移動\n・左矢印キー：左へ移動\n・下矢印キー：下へ移動\n・上矢印キー：90度回転\n・「h」キー：ハードドロップ",
    img: "./img/tetoris.png"
  },
    {
      title: "Remote-Procedure-Call",
      link: "https://github.com/tontatonta/Remote-Procedure-Call",
      about: "このプログラムは、クライアントとサーバー間でのリモートプロシージャコール（RPC）を実現するためのシンプルなシステムです。RPCは、クライアントがリモートサーバー上の関数を呼び出し、データを送信し、サーバーが処理結果を返す通信モデルです。具体的な動きは以下のようになります。1．クライアントが起動され、ユーザーからの入力を待ちます。2．ユーザーがメソッド、パラメーター、およびIDを入力すると、それらの情報を含むRPCリクエストが作成され、サーバーに送信されます。3．サーバーはリクエストを受け取り、指定されたメソッドを実行します。4．実行結果を含むレスポンスがクライアントに送信され、クライアントはそれを受け取ります。5．クライアントはレスポンスを解析し、結果を表示します。次にクライアントが終了するか、新しいリクエストを作成するかを決定します。",
      img: "../img/rpc.png"    
    },
    {
      title: "Local-Chat-Messanger",
      link: "https://github.com/tontatonta/Local-Chat-Messanger",
      about: "このプログラムは、UNIXドメインソケットを介してクライアントとサーバーがテキストメッセージを送受信する仕組みです。クライアントはユーザーからの入力を受け取り、そのテキストをサーバーに送信します。サーバーは受け取ったテキストに応じてランダムな日本語テキストを生成し、それをクライアントに返送します。このプログラムを通じて、クライアントとサーバーは簡単なやり取りを行い、コミュニケーションを実現します。",
      img: "../img/lcm.png"
    },
    {
      title: "ポートフォリオサイト",
      link: "https://myportfolio.inoportfolio.com/",
      about: "Ubuntu、Amazon EC2、NGINXを利用してウェブサーバをセットアップし、静的ウェブサイトをデプロイしています。さらに、DNSを使用して独自のドメインを設定し、HTTPSを導入してセキュリティを強化しています。",
      img: ""
    },
    {
      title: "MarkdowntoHTMLconverter",
      link: "https://github.com/tontatonta/project1",
      about: "マークダウン形式のテキストをHTMLに変換するプログラム．シェルを通して python3 MarkdowntoHTMLconverter.py markdown inputfile outputfile というコマンドを実行することで変換する．markdown　は実行するコマンド，inputfile　は入力するマークダウン形式のファイル，outputfile　は出力するHTML形式のファイル",
      img: "../img/mdconvinput.png"
    },
    {
      title: "Guessthenumbergame",
      link: "https://github.com/tontatonta/project1",
      about: "シェルを通して python3 Guessthenumbergame.py というコマンドを実行することで開始する．入力した最小値と最大値の範囲内で乱数を生成し，生成された乱数を正解するまで答え続ける．正解後，答えた回数を表示する．入力は，最小値，最大値の順番で入力する．",
      img: "../img/randomgame.png"
    },
    {
      title: "File_manuplator",
      link: "https://github.com/tontatonta/project1",
      about: "コマンドラインにて python3 File_manuplator.py reverse inputpath outputpath を実行することで、outputpathにinputpathの内容を逆にした新しいファイルを作成する。\n python3 File_manuplator.py copy inputpath outputpath を実行することで、inputpathにあるファイルのコピーを作成し、outputpathとして保存する。\n python3 File_manuplator.py duplicate-contents inputpath n を実行することで、inputpathにあるファイルの内容を読み込み、その内容を複製し、複製された内容をinputpathにn回複製する。\n python3 File_manuplator.py replace-string inputpath word1 word2 を実行することで、inputpathにあるファイルの内容から文字列word1を検索し、word1の全てをword2に置き換えます。",
      img: "../img/fileoutput.png"
    },
    // 追加のプロジェクト情報をここに追加
  ];
  
  const Project = ({ project }) => {
    return (
      <Section>
        <ProjectCard>
          <ProjectCardContent>
            <ProjectLink href={project.link}>
              <ProjectTitle><strong>{project.title}</strong></ProjectTitle>
            </ProjectLink>
            <ProjectAbout>
              <AboutTitle>--About--</AboutTitle>
              <AboutDescription>{project.about}</AboutDescription>
            </ProjectAbout>
            {project.img && <ProjectImage src={project.img} alt={project.title} />}
          </ProjectCardContent>
        </ProjectCard>
      </Section>
    )
  }
  
  
const Projects = () => {
  return (
    <Container>
      <Title>作製したプロジェクト一覧</Title>
      <div>
        {projectsData.map((project, index) => (
          <Project key={index} project={project} />
        ))}
      </div>
    </Container>
  );
}
  
export default Projects;