import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
  background-color: #333;
  color: white;
  padding: 20px;
`;

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  gap: 20px;
`;

const ListItem = styled.li`
  font-size: 1.2rem;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
`;

export const Header = () => {
  return (
    <HeaderContainer>
      <Navigation>
        <List>
          <ListItem><StyledLink to="/">Home</StyledLink></ListItem>
          <ListItem><StyledLink to="/projects">Projects</StyledLink></ListItem>
        </List>
        <Title><StyledLink to="/">My Portfolio</StyledLink></Title>
      </Navigation>
    </HeaderContainer>
  );
}

export default Header;
